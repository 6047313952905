import React from "react";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { BsFullscreen } from "react-icons/bs";


const FullscreenNavItem = ({toggleFullscreen, iconColor}) => {
    return (
        <div className="nav-item col-sm-3">
            <button className="btn nav-link" onClick={toggleFullscreen}>
                <IconContext.Provider value={iconColor}>
                    <BsFullscreen />
                </IconContext.Provider>
            </button>
        </div>
    );
};

export default FullscreenNavItem;