import React from "react";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { BsCircleHalf } from "react-icons/bs";

const ThemeNavItem = ({
    themeOptions,
    onThemeClick,
    tryCloseMenu,
    themeId,
    toggleBgDropdown,
    iconColor,
    nav,
}) => {
    const ThemeButtons = () => {
        const themeButtons = [];
        themeOptions.forEach((theme) => {
            const onBgThemeClick = () => {
                onThemeClick(theme.id);
                tryCloseMenu();
            };

            let currentButton = (
                <button
                    key={theme.id}
                    className={`btn dropdown-item ${theme.dropdownItem}`}
                    onClick={onBgThemeClick}
                    disabled={theme.id === themeId}
                >
                    <p className="my-0">{`${theme.name}`}</p>
                </button>
            );

            themeButtons.push(currentButton);
        });
        return themeButtons;
    };

    const onBgDropdownClick = () => {
        toggleBgDropdown();
    };

    return (
        <li
            className={
                nav.isBgDropdownOpen
                    ? `nav-item col-sm-3 dropdown show`
                    : `nav-item col-sm-3 dropdown`
            }
        >
            <button
                id="backgroundDropdown"
                className="btn nav-link"
                aria-haspopup="true"
                aria-expanded={nav.isBgDropdownOpen ? "true" : "false"}
                aria-label="Toggle dropdown menu"
                onClick={onBgDropdownClick}
            >
                <IconContext.Provider value={iconColor}>
                    <BsCircleHalf />
                </IconContext.Provider>
            </button>
            <div
                className={
                    nav.isBgDropdownOpen
                        ? `py-0 dropdown-menu dropdown-menu-right show ${themeOptions[themeId].dropdown}`
                        : "py-0 dropdown-menu dropdown-menu-right"
                }
                aria-labelledby="backgroundDropdown"
            >
                <ThemeButtons />
            </div>
        </li>
    );
};

export default ThemeNavItem;
