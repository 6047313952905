import React from "react";
import "./Navbar.css";
import { BsGrid3X3Gap } from "react-icons/bs";
import { IconContext } from "react-icons/lib";
import screenfull from "screenfull";
import InfoModal from "../../modals/InfoModal";
import "../../../services/theme/theme.css";
import { themeOptions } from "../../../services/theme/themeService";
import ClipboardNavItem from "./ClipboardNavItem";
import BackspaceNavItem from "./BackspaceNavItem";
import FullscreenNavItem from "./FullscreenNavItem";
import ThemeNavItem from "./ThemeNavItem";
import DropdownNavItem from "./DropdownNavItem";

const NavBar = (props) => {
    const { nav, options, totalJots, themeId, onThemeClick, tryCloseMenu } =
        props;

    const iconColor = themeOptions[themeId].iconColor;

    //#region Nav Items

    const InfoNavItem = () => {
        return (
            <li className="nav-item col-sm-3">
                <InfoModal themeId={themeId} />
            </li>
        );
    };
    //#endregion

    const handleCopy = () => {
        let pageText = document.getElementById(`textarea-jot`);
        pageText.select();
        document.execCommand("copy");
        pageText.setSelectionRange(0, 0);
        pageText.blur();

        props.toggleCopied();
        tryCloseMenu();
    };

    const handleClear = () => {
        props.onTextClear();
        tryCloseMenu();
        props.toggleCleared();
    };

    const toggleFullscreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }

        tryCloseMenu();
    };

    const NavBarToggleButton = () => {
        // This is the grid icon that toggles the navbar

        const onNavBarClick = (e) => {
            e.preventDefault();
            props.toggleNavBar();
        };

        return (
            <button
                className={
                    nav.isNavBarOpen
                        ? "ml-auto navbar-toggler"
                        : "ml-auto navbar-toggler collapsed"
                }
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded={nav.isNavBarOpen ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={onNavBarClick}
            >
                <IconContext.Provider value={iconColor}>
                    <BsGrid3X3Gap />
                </IconContext.Provider>
            </button>
        );
    };

    return (
        <nav className={"navbar navbar-expand-sm"}>
            <NavBarToggleButton />
            <div
                className={
                    nav.isNavBarOpen
                        ? "navbar-nav navbar-collapse collapse row show"
                        : "navbar-nav navbar-collapse collapse row"
                }
                id="navbarSupportedContent"
            >
                {/*TODO: need to fix this */}
                {nav.isNavBarOpen ? (
                    <ul className="navbar-nav row w-100">
                        <li className="nav-item col-sm-3">
                                <BackspaceNavItem
                                    handleClear={handleClear}
                                    options={options}
                                    iconColor={iconColor}
                                />
                        </li>
                        <li className="nav-item col-sm-3">
                            <ClipboardNavItem
                                options={options}
                                iconColor={iconColor}
                                handleCopy={handleCopy}
                            />
                        </li>
                        {screenfull.isEnabled ? (
                            <li className="nav-item col-sm-3">
                                <FullscreenNavItem
                                    screenfull={screenfull}
                                    toggleFullscreen={toggleFullscreen}
                                    iconColor={iconColor}
                                />
                            </li>
                        ) : null}
                        <li className="nav-item col-sm-3">
                            <ThemeNavItem
                                themeOptions={themeOptions}
                                onThemeClick={onThemeClick}
                                tryCloseMenu={tryCloseMenu}
                                themeId={themeId}
                                toggleBgDropdown={props.toggleBgDropdown}
                                iconColor={iconColor}
                                nav={nav}
                            />
                        </li>
                        <li className="nav-item col-sm-3">
                            <InfoNavItem />
                        </li>
                        <li className="nav-item col-sm-3">
                            <DropdownNavItem
                                themeOptions={themeOptions}
                                themeId={themeId}
                                tryCloseMenu={tryCloseMenu}
                                toggleDropDown={props.toggleDropDown}
                                toggleLines={props.toggleLines}
                                toggleSyllables={props.toggleSyllables}
                                toggleWords={props.toggleWords}
                                addJot={props.addJot}
                                removeJot={props.removeJot}
                                iconColor={iconColor}
                                nav={nav}
                                options={options}
                                totalJots={totalJots}
                            />
                        </li>
                    </ul>
                ) : (
                    <>
                        <div className="row justify-content-center text-center">
                            <BackspaceNavItem
                                handleClear={handleClear}
                                options={options}
                                iconColor={iconColor}
                            />

                            <ClipboardNavItem
                                options={options}
                                iconColor={iconColor}
                                handleCopy={handleCopy}
                            />
                            {screenfull.isEnabled ? (
                                <FullscreenNavItem
                                    screenfull={screenfull}
                                    toggleFullscreen={toggleFullscreen}
                                    iconColor={iconColor}
                                />
                            ) : null}
                        </div>

                        <div className="ml-auto row justify-content-center text-center">
                            <ThemeNavItem
                                themeOptions={themeOptions}
                                onThemeClick={onThemeClick}
                                tryCloseMenu={tryCloseMenu}
                                themeId={themeId}
                                toggleBgDropdown={props.toggleBgDropdown}
                                iconColor={iconColor}
                                nav={nav}
                            />

                            <InfoNavItem />

                            <DropdownNavItem
                                themeOptions={themeOptions}
                                themeId={themeId}
                                tryCloseMenu={tryCloseMenu}
                                toggleDropDown={props.toggleDropDown}
                                toggleLines={props.toggleLines}
                                toggleSyllables={props.toggleSyllables}
                                toggleWords={props.toggleWords}
                                addJot={props.addJot}
                                removeJot={props.removeJot}
                                iconColor={iconColor}
                                nav={nav}
                                options={options}
                                totalJots={totalJots}
                            />
                        </div>
                    </>
                )}
            </div>
        </nav>
    );
};

export default NavBar;
