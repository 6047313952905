import React from "react";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { IoIosCopy } from "react-icons/io";

const ClipboardNavItem = ({options, iconColor, handleCopy}) => {
    
    const onCopyClick = (e) => {
        e.preventDefault();

        handleCopy();
    };

    return (
        <div className="nav-item col-sm-3">
            <button
                className="btn nav-link"
                onClick={onCopyClick}
                disabled={options.copied || options.cleared ? true : false}
            >
                <IconContext.Provider value={iconColor}>
                    <IoIosCopy />
                </IconContext.Provider>
            </button>
        </div>
    );
};

export default ClipboardNavItem;