import React from "react";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { BsFillCaretUpFill, BsFillCaretDownFill } from "react-icons/bs";

const DropdownNavItem = ({
    themeOptions,
    themeId,
    tryCloseMenu,
    toggleDropDown,
    toggleLines,
    toggleSyllables,
    toggleWords,
    addJot,
    removeJot,
    iconColor,
    nav,
    options,
    totalJots,
}) => {
    const onNavDropDownClick = (e) => {
        e.preventDefault();
        toggleDropDown();
    };

    const onToggleLinesClick = () => {
        toggleLines();
        tryCloseMenu();
    };

    const onToggleSyllablesClick = () => {
        toggleSyllables();
        tryCloseMenu();
    };

    const onToggleWordsClick = () => {
        toggleWords();
        tryCloseMenu();
    };

    const onAddSheetClick = () => {
        addJot();
        tryCloseMenu();
    };

    const onRemoveSheetClick = () => {
        removeJot();
        tryCloseMenu();
    };

    return (
        <div
            className={
                nav.isOptionsDropdownOpen
                    ? `nav-item col-sm-3 dropdown show`
                    : `nav-item col-sm-3 dropdown`
            }
        >
            <button
                id="navbarDropdown"
                className="btn nav-link"
                aria-haspopup="true"
                aria-expanded={nav.isOptionsDropdownOpen ? "true" : "false"}
                aria-label="Toggle dropdown menu"
                onClick={onNavDropDownClick}
            >
                <IconContext.Provider value={iconColor}>
                    {nav.isOptionsDropdownOpen ? (
                        <BsFillCaretUpFill />
                    ) : (
                        <BsFillCaretDownFill />
                    )}
                </IconContext.Provider>
            </button>
            <div
                className={
                    nav.isOptionsDropdownOpen
                        ? `py-0 dropdown-menu dropdown-menu-right show ${themeOptions[themeId].dropdown}`
                        : "py-0 dropdown-menu dropdown-menu-right"
                }
                aria-labelledby="navbarDropdown"
            >
                <button
                    className={`btn dropdown-item ${themeOptions[themeId].dropdownItem}`}
                    onClick={onToggleLinesClick}
                >
                    <p className="my-0">{`Line Counter: ${
                        options.lines ? "On" : "Off"
                    }`}</p>
                </button>
                <button
                    className={`btn dropdown-item ${themeOptions[themeId].dropdownItem}`}
                    onClick={onToggleSyllablesClick}
                >
                    <p className="my-0">{`Syllable Counter: ${
                        options.syllables ? "On" : "Off"
                    }`}</p>
                </button>
                <button
                    className={`btn dropdown-item ${themeOptions[themeId].dropdownItem}`}
                    onClick={onToggleWordsClick}
                >
                    <p className="my-0">{`Word Counter: ${
                        options.words ? "On" : "Off"
                    }`}</p>
                </button>
                <button
                    className={`btn dropdown-item ${themeOptions[themeId].dropdownItem}`}
                    onClick={onAddSheetClick}
                >
                    Add Sheet
                </button>
                {totalJots > 1 ? (
                    <button
                        className={`btn dropdown-item ${themeOptions[themeId].dropdownItem}`}
                        onClick={onRemoveSheetClick}
                    >
                        Remove Sheet
                    </button>
                ) : null}
            </div>
        </div>
    );
};

export default DropdownNavItem;
