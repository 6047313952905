import React from "react";
import "./Navbar.css";
import { IconContext } from "react-icons/lib";
import { BsBackspaceFill } from "react-icons/bs";

const BackspaceNavItem = ({handleClear, options, iconColor}) => {
    const onClearClick = (e) => {
        e.preventDefault();
        handleClear();
    };

    return (
        <div className="nav-item col-sm-3">
            <button
                className="btn nav-link"
                onClick={onClearClick}
                disabled={options.copied || options.cleared ? true : false}
            >
                <IconContext.Provider value={iconColor}>
                    <BsBackspaceFill />
                </IconContext.Provider>
            </button>
        </div>
    );
};

export default BackspaceNavItem;
